import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FlightSearchForm from "../../components/flight-search-form/FlightSearchForm.component";
import moment from "moment";
import "./Homepage.styles.css";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import PopupPage from "../popup-deals/Popuppage";
import axios from "axios";
import { theme_airline_url } from "../../utils/apiInfo";
import BestTour from "../../components/BestTours/BestTour";
import BestTourAu from "../../components/BestTours/BestTourAu";
import BestTourCa from "../../components/BestTours/BestTourCa";
import BestTourIn from "../../components/BestTours/BestTourIn";
import BestTourNz from "../../components/BestTours/BestTourNz";
import BestTourPh from "../../components/BestTours/BestTourPh";
import BestTourQa from "../../components/BestTours/BestTourQa";
import BestTourRp from "../../components/BestTours/BestTourRp";
import BestTourSg from "../../components/BestTours/BestTourSg";
import BestTourUAE from "../../components/BestTours/BestTourUAE";
import BestTourHk from "../../components/BestTours/BestTourHk";
import BestTourMy from "../../components/BestTours/BestTourMy";
import BestTourZa from "../../components/BestTours/BestTourZa";
import BestTourTh from "../../components/BestTours/BestTourTh";
import BestTourUk from "../../components/BestTours/BestTourUk";
import BestTourVn from "../../components/BestTours/BestTourVn";
import Aeroplane from "../../assets/images/new/down.png"
import smiling from "../../assets/images/new/smiling.jpg"

const HomePage = () => {
  const navigate = useNavigate();
  const [res, setRes] = useState();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleExpClick = (origin, dest) => {
    navigate({
      pathname: "/flights",
      search: `?search_t=${moment().unix()}&tripType=one-way&dep_loc=${origin}&dest_loc=${dest}&dep_dt=${moment().format(
        "YYYY-MM-DD"
      )}&ret_dt=null&fl_cl=ECONOMY&adt=1&chd=0`,
    });
  };

  const loadBestTour = () => {
    let urlname
    urlname = window.location.hostname.split('.')[0];
    if (urlname === 'localhost') {
      urlname = "au"
    } else {
      if (urlname === 'www') {
        urlname = "au"
      }
    }



    return (
      <>
        {urlname === 'Globalfarehub' ? <BestTour /> : ""}
        {urlname === 'au' ? <BestTourAu /> : ""}
        {urlname === 'ca' ? <BestTourCa /> : ""}
        {urlname === 'hk' ? <BestTourHk /> : ""}
        {urlname === 'in' ? <BestTourIn /> : ""}
        {urlname === 'id' ? <BestTourRp /> : ""}
        {urlname === 'my' ? <BestTourMy /> : ""}
        {urlname === 'nz' ? <BestTourNz /> : ""}
        {urlname === 'ph' ? <BestTourPh /> : ""}
        {urlname === 'qa' ? <BestTourQa /> : ""}
        {urlname === 'sg' ? <BestTourSg /> : ""}
        {urlname === 'sa' ? <BestTourZa /> : ""}
        {urlname === 'th' ? <BestTourTh /> : ""}
        {urlname === 'en' ? <BestTour /> : ""}
        {urlname === 'es' ? <BestTour /> : ""}
        {urlname === 'ae' ? <BestTourUAE /> : ""}
        {urlname === 'uk' ? <BestTourUk /> : ""}
        {urlname === 'vn' ? <BestTourVn /> : ""}
      </>
    )
  }
  // const [showPopup, setPopup] = useState('active')

  // function removePopup(){
  //   setPopup('')
  // }

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
  };

  const urlFull = window.location.href;
  useEffect(() => {
    axios
      .get(`${theme_airline_url}`, {
        params: {
          url: urlFull,
        },
      })
      .then((response) => {
        setRes(response.data.status);
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  }, [urlFull]);
  return (
    <React.Fragment>
      <Helmet>
        <title>Globalfarehub | Home </title>
      </Helmet>
      {res === "1" ? <PopupPage /> : ""}


      <section className="head-section">
        <div className="container">
          <div className="hero-text text-center">
            <h1>Fly smart, fly easy with Globalfarehub <br /> Your ticket to the skies!</h1>
          </div>
          <div className="booking-form-main">
            <FlightSearchForm />
          </div>
        </div>
      </section>

      <section className="gap">
        <div className="container">
          <div class="service-text">
            <h2><span>Why Choose </span> Our Services</h2>
          </div>
          <div class="reasons flex space-bw">
            <div class="reason col-25 text-center">
              <i class="fa-solid fa-dollar-sign"></i>
              <h3>Incredible Offers</h3>
              <p>Get exclusive, unpublished fares for maximum savings</p>
            </div>
            <div class="reason col-25 text-center">
              <i class="fa-solid fa-user"></i>
              <h3>Seamless Booking Experience</h3>
              <p>An easy-to-navigate interface ensuring quick and stress-free reservations</p>
            </div>
            <div class="reason col-25 text-center">
              <i class="fa-solid fa-clock"></i>
              <h3>Around-the-Clock Help</h3>
              <p>Our dedicated team provides 24/7 customer service</p>
            </div>
            <div class="reason col-25 text-center">
              <i class="fa-solid fa-plane"></i>
              <h3>Credible Partners</h3>
              <p>Partnered with top-tier airlines to guarantee dependable flight services worldwide</p>
            </div>
          </div>
        </div>
      </section>

      <section class="gap">
        <div class="container">
          <div class="service-text">
            <h2>Discover Our <span>Best Selections</span></h2>
          </div>
          <div class="packages flex space-bw">
            <div class="pack-detail col-25">
              <div class="package-image">
                <img src={require("../../assets/images/new/paris.jpg")} alt="" />
              </div>
              <div class="package-text-top">
                <h3>Paris, France</h3>
                <p>Experience the enchanting charm of Paris</p>
                <button onClick={(e) => handleExpClick("PAR", "POS")}>Start exploring</button>
              </div>
            </div>
            <div class="pack-detail col-25">
              <div class="package-image">
                <img src={require("../../assets/images/new/hawai.jpg")} alt="" />
              </div>
              <div class="package-text-top">
                <h3>Hawaii, USA</h3>
                <p>Hawaii: Your gateway to paradise</p>
                <button onClick={(e) => handleExpClick("HNL", "NYC")}>Start exploring</button>
              </div>
            </div>
            <div class="pack-detail col-25">
              <div class="package-image">
                <img src={require("../../assets/images/new/cape.jpg")} alt="" /></div>
              <div class="package-text-top">
                <h3>Cape Town, South Africa</h3>
                <p>Where adventure thrives in vibrant landscapes</p>
                <button onClick={(e) => handleExpClick("CPT", "NAP")}>Start exploring</button>
              </div>
            </div>
            <div class="pack-detail col-25">
              <div class="package-image">
                <img src={require("../../assets/images/new/japan.jpg")} alt="" />
              </div>
              <div class="package-text-top">
                <h3>Kyoto, Japan</h3>
                <p>Witness Japan's timeless beauty firsthand</p>
                <button onClick={(e) => handleExpClick("UKY", "OSA")}>Start exploring</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="gap review-slider calltoact">
        <div className="container">
          <div class="service-text">
            <h2 className="col-white">Customer Feedback</h2>
          </div>
          <div className="testimonials-slider">
            <Slider {...settings}>
              <div className="tst-slide">
                <div class="quotes">
                  <i class="fa-solid fa-quote-left" aria-hidden="true"></i>
                </div>
                <p>Booking my flight through Globalfarehub was a breeze! Their website is so easy to use, and I found great deals quickly. I highly recommend them!</p>
                <h3>Sophia Williams</h3>
                <div class="star">
                  <i class="fa-solid fa-star" aria-hidden="true"></i>
                  <i class="fa-solid fa-star" aria-hidden="true"></i>
                  <i class="fa-solid fa-star" aria-hidden="true"></i>
                  <i class="fa-solid fa-star" aria-hidden="true"></i>
                  <i class="fa-solid fa-star" aria-hidden="true"></i>
                </div>
              </div>
              <div className="tst-slide">
                <div class="quotes">
                  <i class="fa-solid fa-quote-left" aria-hidden="true"></i>
                </div>
                <p>The service from Globalfarehub was impressive! I needed a last-minute ticket, and they provided excellent customer support. I'll definitely be using them again!</p>
                <h3>James Smith</h3>
                <div class="star">
                  <i class="fa-solid fa-star" aria-hidden="true"></i>
                  <i class="fa-solid fa-star" aria-hidden="true"></i>
                  <i class="fa-solid fa-star" aria-hidden="true"></i>
                  <i class="fa-solid fa-star" aria-hidden="true"></i>
                  <i class="fa-solid fa-star-half"></i>
                </div>
              </div>
              <div className="tst-slide">
                <div class="quotes">
                  <i class="fa-solid fa-quote-left" aria-hidden="true"></i>
                </div>
                <p>Globalfarehub surpassed my expectations! Booking was a breeze, prices were competitive, and the service was dependable. It's just what frequent flyers need!</p>
                <h3>Emily Johnson</h3>
                <div class="star">
                  <i class="fa-solid fa-star" aria-hidden="true"></i>
                  <i class="fa-solid fa-star" aria-hidden="true"></i>
                  <i class="fa-solid fa-star" aria-hidden="true"></i>
                  <i class="fa-solid fa-star" aria-hidden="true"></i>
                  <i class="fa-solid fa-star" aria-hidden="true"></i>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>

      <section className="gap">
        <div className="container flex space-bw">
          <div className="col-60 smiling-girl">
            <p>Contact us now for immediate assistance! Benefit from our no-wait policy and receive fast help whenever you need it. Our team is available 24/7 to provide efficient service that fits your needs perfectly. Don’t delay—call us now and experience quick assistance firsthand!</p>
            <a href="tel:+18882296183"><i className="fa-solid fa-phone-volume"></i>+1-888-229-6183</a>
          </div>
          <div className="col-40 smiling-girl">
            <img src={smiling} alt="" />
            <span>
              <i class="fa-solid fa-phone-volume fa-shake"></i>
            </span>
          </div>
        </div>
      </section>

    </React.Fragment >
  );
};

export default HomePage;
