import React, { useEffect, useState } from "react";
import "./About.styles.css";
import about_fuss from "../../assets/images/about/about_fuss.jpg";
import SEO from "../../utils/SEO";
import { Helmet } from "react-helmet";

const AboutPage = () => {

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <SEO title="About Page" description="description"/> */}
      <div className="cm-about-page-container">
        <Helmet>
          <title>Globalfarehub | About us</title>
        </Helmet>

        <div className="inner-banner">
          <h2 className="cm-section-h cm-txt-center cm-white-col">About Us</h2>
        </div>

        <div className="cm-section cm-about-us-container">
          <div className="cm-page-center cm-flex-type-1">
            <div className="cm-left-col">
              <h2 className="cm-section-h">
                Catch the Greatest Flight Deals and Commence Your Journey with Globalfarehub.com  </h2>
              <p className="cm-section-sp">
                Globalfarehub.com offers competitive airfares and discounts on online flight bookings to major destinations in the USA, UK, and Canada. As a leading booking platform, we aim to streamline your experience by tackling every obstacle you face when searching for flight schedules online.
              </p>
              {/* <ul>
              <li>
                <strong>Promising Deals</strong> -
                <span>
                  We strive to make online booking hassle-free for our customers
                  across USA, UK, and Canada.
                </span>
              </li>
              <li>
                <strong>Redefined Experience</strong> -
                <span>
                  Our team at Trian Fly focuses on improving the travel
                  experience with impeccable flight deals.
                </span>
              </li>
              <li>
                <strong>All-Embracing Offerings</strong> -
                <span>
                  Our team at Trian Fly focuses on improving the travel
                  experience with impeccable flight deals.
                </span>
              </li>
              <li>
                <strong>Ceaseless Support</strong> -
                <span>
                  Need assistance while booking budget-friendly flights online?
                  Our support team is here to help!
                </span>
              </li>
            </ul> */}
            </div>
            <div className="cm-right-col">
              <i className="fa-solid fa-plane-circle-check cm-flex-type-2 cm-prim-bg cm-white-col"></i>
            </div>
          </div>
        </div>


        <div className="service_section">
          <div className="cm-page-center">
            <h2 style={{ marginBottom: "15px" }}>Why Globalfarehub.com ?</h2>
          </div>
          <div className="cm-page-center cm-flex cm-flex-wrap">
            <div className="cm-wd-25">
              <i className="fa-solid fa-tag"></i>
              <h4>Exciting Offers</h4>
              <p>Simplifying online booking for customers throughout the USA, UK, and Canada</p>
            </div>
            <div className="cm-wd-25">
              <i className="fa-solid fa-circle-check"></i>
              <h4>Transformed Experience</h4>
              <p>Enhancing travel with exceptional flight deals at Globalfarehub.com</p>
            </div>
            <div className="cm-wd-25">
              <i className="fa-solid fa-heart"></i>
              <h4>Comprehensive Services</h4>
              <p>Globalfarehub.com aims to elevate travel experiences by offering exceptional flight deals</p>
            </div>
            <div className="cm-wd-25">
              <i className="fa-solid fa-clock"></i>
              <h4>Persistent Help</h4>
              <p>Need help booking budget-friendly flights online? Our support team is always available!</p>
            </div>
          </div>
        </div>

        <div className="cm-section cm-about-fuss-container">
          <div className="cm-page-center cm-flex-type-1">
            <div className="cm-left-col">
              <img
                src={about_fuss}
                alt="Fuss-Free Flight Booking"
                className="box-shadow-2"
              />
            </div>
            <div className="cm-right-col">
              <h2 className="cm-section-h">
                Hassle-Free Flight Reservations for Future Fond Memories
              </h2>
              <p>
                Whether it's a crucial business meeting, a family gathering, or a well-deserved escape from work, travel remains a constant in our lives. With our extensive experience in travel services, we understand the ongoing hurdles individuals encounter when organizing their journeys.
              </p>
              <p>
                We aim to enhance your online flight booking experience by providing exceptional deals and discounts. We believe in cost-effective travel planning as the foundation for unforgettable
                trips. Count on us to find the best flights within your budget and preferred travel times.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutPage;
